"use strict";
import angular from "angular";

import auth from "./auth";
import notify from "./notify";
import api from "./api";
import common from "./common";
import paperwork from "./paperwork";
import advisor from "./advisor";
import publicWafla from "./wafla";
let module = angular.module("app.common", [
    auth.name,
    notify.name,
    api.name,
    common.name,
    paperwork.name,
    advisor.name,
    publicWafla.name,
]);

export default module;

import workerSelect from "./workerSelect";
import workerSelectSingle from "./workerSelectSingle";
import createAccount from "./createAccount";
import dynamicMap from "./dynamicMap";
import placesAutocomplete from "./placesAutocomplete";
import sectionHeading from "./sectionHeading";
import infoBlock from "./infoBlock";
import takeProfilePicture from "./takeProfilePicture";
import qrCodeGenerator from "./qrCodeGenerator";
import newFeatureTag from "./newFeatureTag";
import resourcesCard from "./resourcesCard";
import resourceIcon from "./resourceIcon";
import selectedObjectsList from "./selectedObjectsList";
import transcriptionInput from "./transcriptionInput";
export default [
    dynamicMap,
    workerSelect,
    workerSelectSingle,
    createAccount,
    placesAutocomplete,
    sectionHeading,
    infoBlock,
    takeProfilePicture,
    qrCodeGenerator,
    newFeatureTag,
    resourcesCard,
    resourceIcon,
    selectedObjectsList,
    transcriptionInput,
];

import template from "./snippet.html";
import en from "./en.translation.json";
import es from "./es.translation.json";
import _ from "lodash";
import mixpanel from "mixpanel-browser";
import { advisorShareState } from "../../../../app.routes";

class SnippetController {
    /* @ngInject */
    constructor($state, ToastService, $scope) {
        this.$state = $state;
        this.ToastService = ToastService;
        this.$scope = $scope;
        this.shouldIndex = true;
    }

    $onInit() {
        let ctrl = this;
        ctrl.messages = _.orderBy(ctrl.messages, ["index"], ["asc"]);
    }

    share(shouldIndex) {
        let ctrl = this;
        ctrl.showSpinner = true;
        ctrl.snippet.share(shouldIndex).then(function (snippet) {
            let shareableUrl = ctrl.$state.href(
                advisorShareState,
                { snippet_sid: snippet.sid },
                { absolute: true },
            );
            if (navigator.share) {
                navigator
                    .share({
                        title: "Harvust Advisor",
                        text: "Check out this snippet from Harvust Advisor!",
                        url: shareableUrl,
                    })
                    .then(function () {
                        ctrl.$scope.$applyAsync(function () {
                            ctrl.showSpinner = false;
                            ctrl.shouldIndex = true;
                        });
                        mixpanel.track("Advisor snippet shared", {
                            share_method: "navigator.share",
                            shared_from: "snippet",
                        });
                    });
            } else {
                navigator.clipboard.writeText(shareableUrl).then(function () {
                    ctrl.$scope.$applyAsync(function () {
                        ctrl.ToastService.create(
                            "advisorSnippet.SHARE_CLIPBOARD_TOAST",
                        );
                        ctrl.showSpinner = false;
                        ctrl.shouldIndex = true;
                    });
                    mixpanel.track("Advisor snippet shared", {
                        share_method: "clipboard",
                        shared_from: "snippet",
                    });
                });
            }
        });
    }
}

export default {
    bindings: {
        snippet: "<",
        messages: "<",
    },
    controller: SnippetController,
    template: template,
    name: "advisorSnippet",
    translations: { en, es },
};

import template from "./advisor_home.html";
import en from "./en.translation.json";
import es from "./es.translation.json";
import { ACTIVE, CLOSED } from "../../../communication/constants";
import _ from "lodash";
import angular from "angular";
import { ENGLISH, SPANISH } from "../../../../app.constants";
import { advisorConversationState } from "../../../../app.routes";
import mixpanel from "mixpanel-browser";
import { addBreadcrumb, captureMessage } from "@sentry/browser";
import logoUrl from "../../../../../assets/harvust-logo-008.svg";

class AdvisorHomeController {
    /* @ngInject */
    constructor(
        AccountService,
        $state,
        ToastService,
        $http,
        AuthService,
        BeamsService,
    ) {
        this.AccountService = AccountService;
        this.$state = $state;
        this.ToastService = ToastService;
        this.$http = $http;
        this.AuthService = AuthService;
        this.BeamsService = BeamsService;
        this.LOGO_URL = logoUrl;
        let ctrl = this;
    }

    signup(params) {
        console.log(params);
        let ctrl = this;
        ctrl.$http
            .post(process.env.API_DOMAIN + "/api/users/signup", params, {
                skipAuthorization: true,
            })
            .then(
                function (response) {
                    let token = response.data.token.refresh;
                    addBreadcrumb({
                        category: "auth",
                        message: "Picked Token: " + token,
                    });
                    ctrl.AuthService.setRefreshToken(token);
                    ctrl.AuthService.setLoggedInFlag();
                    return ctrl.AuthService.getAuthenticatedUserSid().then(
                        function (userSid) {
                            ctrl.AccountService.updatePermissions([], []);
                            ctrl.AccountService.retrieve(userSid).then(
                                function (account) {
                                    account.updateSentry();
                                    account.updateMixpanel();
                                    account.updateLanguage();
                                    ctrl.$state.go(advisorConversationState);
                                },
                            );
                        },
                        function () {
                            addBreadcrumb({
                                category: "auth",
                                message: "Could not get user sid from token",
                            });
                            ctrl.AuthService.logout();
                            captureMessage(
                                "Logging out due to unable to get user sid from token",
                            );
                            ctrl.BeamsService.stop();
                        },
                    );
                },
                function (reason) {
                    ctrl.ToastService.error("Error creating account");
                },
            );
    }
}

export default {
    bindings: {},
    controller: AdvisorHomeController,
    template: template,
    name: "advisorHome",
    translations: { en, es },
};

import template from "./advisor_sign_up.html";
import en from "./en.translation.json";
import es from "./es.translation.json";
import _ from "lodash";
import mixpanel from "mixpanel-browser";

class AdvisorSignUpController {
    /* @ngInject */
    constructor($state, ToastService, $http, $document) {
        this.$state = $state;
        this.ToastService = ToastService;
        this.$http = $http;
        this.$document = $document;
        let ctrl = this;
    }

    signup(params) {
        let ctrl = this;
        ctrl.showSpinner = true;
        ctrl.$http
            .post(process.env.API_DOMAIN + "/api/users/signup", params, {
                skipAuthorization: true,
            })
            .then(
                function (response) {
                    ctrl.showPostSignup = true;
                    mixpanel.track("Signed up for advisor frontend", {
                        email: params.email,
                        page_title: ctrl.$document[0].title,
                    });
                },
                function (reason) {
                    ctrl.showSpinner = false;
                    if (reason.status == 400) {
                        ctrl.errors = reason.data;
                    } else {
                        ctrl.ToastService.create(
                            "advisorSignUp.ACCOUNT_CREATION_ERROR",
                        );
                    }
                },
            );
    }
}

export default {
    bindings: {},
    controller: AdvisorSignUpController,
    template: template,
    name: "advisorSignUp",
    translations: { en, es },
};

import _ from "lodash";
import { ENGLISH, SPANISH } from "../../app.constants";
import mixpanel from "mixpanel-browser";
import {
    CREW_FILTER,
    DATE_ADDED_END_FILTER,
    DATE_ADDED_START_FILTER,
    DOCUMENT_STATUS_FILTER,
    WORKER_STATUS_FILTER,
} from "../common/common.filters";
import moment from "moment";

export function wrap_option(value) {
    if (!_.isNil(value)) {
        return {
            label: value,
            value: value,
        };
    }
}

export function getDataFromForm(data, form, schema) {
    let renderedFieldNames = _.filter(
        _.uniq(_.map(form.$getControls(), "$name")),
        function (name) {
            return !_.isEmpty(name);
        },
    );
    // let emptyFromForm = _.zipObject(renderedFieldNames);
    // return _.pick(_.merge(emptyFromForm, data), renderedFieldNames);
    let schemaFields = _.keys(schema.properties);

    // Get blank data object with keys for all the fields this user could enter data for.
    let empty = {};
    _.forEach(schemaFields, (field) => (empty[field] = null));
    // let empty = _.zipObject(schemaFields);

    // Get the form data for the fields that were actually rendered to the user.
    let formData = _.pick(data, renderedFieldNames);

    // Merge the entered data into the blank.
    return _.merge(empty, formData);
}

export function normalizeFormElements($scope, form) {
    $scope.$watchCollection(form.name + ".$$controls", function () {
        let inputs = form.querySelectorAll("input");
        _.forEach(inputs, (input) => {
            input.setAttribute("autocomplete", "stop-google"); // This is a hack, but it works
        });
    });
}

export function addAudioLabels(
    $timeout,
    $translate,
    $window,
    $q,
    $document,
    form,
) {
    $timeout(function () {
        let labels = form.querySelectorAll("label");
        let synth = $window.speechSynthesis;
        if (synth) {
            let allVoicesObtained = $q.defer();
            let voices = synth.getVoices();
            if (voices.length !== 0) {
                allVoicesObtained.resolve(voices);
            } else {
                synth.addEventListener("voiceschanged", function () {
                    voices = synth.getVoices();
                    allVoicesObtained.resolve(voices);
                });
            }

            function getPreferredVoice(voices, language) {
                let preferences = {};
                preferences[ENGLISH] = ["en-US", "en"];
                preferences[SPANISH] = ["es-MX", "en"];
                for (let lang of preferences[language]) {
                    let voice = _.find(voices, ["lang", lang]);
                    if (voice) {
                        return voice;
                    }
                }
            }

            allVoicesObtained.promise.then(function (voices) {
                let language = $translate.use();
                let voice = getPreferredVoice(voices, language);
                _.forEach(labels, (label) => {
                    let utterance = new $window.SpeechSynthesisUtterance(
                        label.innerText,
                    );
                    utterance.lang = language;
                    utterance.voice = voice;
                    let audioElement = $document[0].createElement("span");
                    audioElement.innerText = "🔊";
                    audioElement.classList.add("audio-label");
                    audioElement.onclick = function () {
                        if (!synth.speaking) {
                            synth.speak(utterance);
                            mixpanel.track("Played simple form label");
                        }
                    };
                    label.appendChild(audioElement);
                });
            });
        }
    });
}

export function filterOutBadEnums(prefillData, formSchema) {
    _.forEach(prefillData, function (value, field) {
        let schemaProperty = formSchema.properties[field];
        if (
            schemaProperty &&
            schemaProperty.enum &&
            !_.includes(schemaProperty.enum, value)
        ) {
            prefillData[field] = "";
        }
    });
    return prefillData;
}

export function removeNulls(data) {
    return _.pickBy(data, _.negate(_.isNull));
}

export function prependFormKeyToInitialData(data, prefix) {
    return _.mapKeys(data, function (value, key) {
        return prefix + "__" + key;
    });
}

export function removeFormKeyFromData(data) {
    return _.mapKeys(data, function (value, key) {
        let fieldName = key.split("__")[1];
        if (fieldName) {
            return fieldName;
        } else {
            return key;
        }
    });
}

export function removeEmptyValues(data) {
    return _.pickBy(data, function (value) {
        return !_.isNil(value);
    });
}

export function parseDataFromFeatheryV1(fields) {
    let noprefixData = removeFormKeyFromData(fields);
    let dataToGo = {};
    _.forEach(noprefixData, function (fieldData, key) {
        let value = _.get(fieldData, "value", null);
        if (value === "") {
            value = null;
        }
        dataToGo[key] = value;
    });
    return dataToGo;
}

export function getFeatheryFieldsWithErrors(errors) {
    return _.pickBy(errors, _.negate(_.isEmpty));
}

export function getFieldsThatAreOnThisForm(fields) {
    return _.pickBy(fields, "onThisForm");
}

export function focusFirstFieldWithError(fieldsWithErrors) {
    let firstFieldId = _.first(_.keys(fieldsWithErrors));
    let field = document.getElementById(firstFieldId);
    field.focus();
}

export function waitForCompletedSignatureRequest(signatureRequest) {
    return signatureRequest.refresh();
    // return promisePoller({
    //     retries: 15,
    //     taskFn: function () {
    //         return signatureRequest.get().then(function (request) {
    //             if (request.status === PENDING) {
    //                 throw new TypeError();
    //             }
    //             return request;
    //         });
    //     },
    // });
}

export function getFirstFieldWithErrorBase(form, erredControls) {
    let orderedFields = _.filter(
        form.$$element[0].elements,
        function (element) {
            return _.includes(_.map(erredControls, "$name"), element.name);
        },
    );
    return _.first(orderedFields);
}

export function getFirstFieldWithError(form) {
    let errors = form.$error;
    let schemaFormErrors = _.get(errors, "schemaForm", []);
    let tv302Errors = _.get(errors, "tv4-302", []);
    let erredControls = _.concat(schemaFormErrors, tv302Errors);
    return getFirstFieldWithErrorBase(form, erredControls);
}

export function getFirstFieldWithErrorI9(form) {
    let errors = form.$error;
    let requiredErrors = _.get(errors, "required", []);
    errors = _.omit(errors, "required");
    let errorsErrors = _.get(errors, "errors", []);
    errors = _.omit(errors, "errors");
    let erredControls = _.concat(
        _.map(_.values(errors), _.first),
        requiredErrors,
        errorsErrors,
    );
    return getFirstFieldWithErrorBase(form, erredControls);
}

export const SORTS = {
    first_name: {
        label: "paperwork.onboardingExplorer.SORT_BY_FIRST_NAME",
        params: { order_by: "first_name" },
        func: function (element) {
            return _.toLower(_.get(element, "growerMembership.first_name", ""));
        },
        order: "asc",
    },
    last_name: {
        label: "paperwork.onboardingExplorer.SORT_BY_LAST_NAME",
        params: { order_by: "last_name" },
        func: function (element) {
            return _.toLower(_.get(element, "growerMembership.last_name", ""));
        },
        order: "asc",
    },
    date_added: {
        label: "paperwork.onboardingExplorer.SORT_BY_DATE_ADDED",
        params: { order_by: "date_added" },
        func: function (element) {
            return moment(element.farmMembership.date_added);
        },
        order: "desc",
    },
    employee_id: {
        label: "paperwork.onboardingExplorer.SORT_BY_EMPLOYEE_ID",
        params: { order_by: "employee_id" },
        func: function (element) {
            return element.growerMembership.employee_id;
        },
        order: "asc",
    },
};
export const FILTERS = {
    status: WORKER_STATUS_FILTER,
    document_status: DOCUMENT_STATUS_FILTER,
    date_added_start: DATE_ADDED_START_FILTER,
    date_added_end: DATE_ADDED_END_FILTER,
    crew: CREW_FILTER,
};
export const ONBOARDING_REPORT_FILTERS = {
    status: WORKER_STATUS_FILTER,
    document_status: DOCUMENT_STATUS_FILTER,
    date_added_start: DATE_ADDED_START_FILTER,
    date_added_end: DATE_ADDED_END_FILTER,
    crew: CREW_FILTER,
    document_packages: {
        func: function (element, test) {
            return true;
        },
    },
    calendar_year: {
        func: function (element, test) {
            return true;
        },
    },
};

export function getNextRole(documentPackageRequest, documentPackage) {
    let signingOrder = _.get(documentPackage, "signing_order", {});
    return _.invert(signingOrder)[
        documentPackageRequest.latest_signer_order + 1
    ];
}

export function getRemainingRoles(documentPackageRequest, documentPackage) {
    let signingOrder = _.get(documentPackage, "signing_order", {});
    return _.keys(
        _.pickBy(signingOrder, function (value, key) {
            return value > documentPackageRequest.latest_signer_order;
        }),
    );
}

export function getSignatureFieldNames(documentPackage) {
    let properties = documentPackage.translations[0].schema.properties;
    let fieldNames = [];
    for (let field in properties) {
        if (properties.hasOwnProperty(field)) {
            if (properties[field]["x-input-type"] === "signature") {
                fieldNames.push(field);
            }
        }
    }
    return fieldNames;
}

export function getSchemaFromTranslation(preferredLanguage, translations) {
    let schema = {};
    let languagesToTry = [preferredLanguage, ENGLISH, SPANISH];
    for (let language of languagesToTry) {
        let translation = _.find(translations, { language });
        if (translation) {
            schema = translation.schema;
            break;
        }
    }
    return schema;
}

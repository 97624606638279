import customDocumentScanTemplate from "./custom_document_scan.html";
import en from "./en.translation.json";
import es from "./es.translation.json";
import {
    UPLOAD_PENDING,
    UPLOAD_SUCCESS,
    FACING_MODE_ENVIRONMENT,
} from "../../../common/common.constants";
import mixpanel from "mixpanel-browser";

class CustomDocumentScanController {
    /* @ngInject */
    constructor($http, ToastService, $window, $state, $q, CanvasService) {
        this.$http = $http;
        this.ToastService = ToastService;
        this.$window = $window;
        this.$state = $state;
        this.$q = $q;
        this.CanvasService = CanvasService;

        this.UPLOAD_PENDING = UPLOAD_PENDING;
        this.UPLOAD_SUCCESS = UPLOAD_SUCCESS;
        this.cameraAllowed = true;
    }

    captureVideo() {
        let ctrl = this;
        let options = {
            video: {
                width: { ideal: 1920 },
                height: { ideal: 1080 },
                facingMode: FACING_MODE_ENVIRONMENT,
                frameRate: {
                    ideal: 30,
                },
            },
            audio: false,
        };
        ctrl.$q
            .when(ctrl.$window.navigator.mediaDevices.getUserMedia(options))
            .then(
                function (stream) {
                    ctrl.cameraAllowed = true;
                    ctrl.video.srcObject = stream;
                    ctrl.video.play();
                },
                function () {
                    ctrl.cameraAllowed = false;
                },
            );
    }

    $onInit() {
        let ctrl = this;
        if (ctrl.userSid) {
            ctrl.video = document.getElementById("video");
            ctrl.captureVideo();
        } else {
            ctrl.uploadStatus = UPLOAD_SUCCESS;
        }
    }

    $onDestroy() {
        let ctrl = this;
        if (ctrl.video && ctrl.video.srcObject) {
            ctrl.endVideoCapture();
        }
    }

    endVideoCapture() {
        let ctrl = this;
        for (let track of ctrl.video.srcObject.getTracks()) {
            track.stop();
        }
    }

    takePhoto() {
        let ctrl = this;
        let canvas = document.getElementById("canvas");
        let video = ctrl.video;

        ctrl.uploadStatus = UPLOAD_PENDING;

        // Set image
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        let context = canvas.getContext("2d", { alpha: false });
        video.pause();
        context.drawImage(video, 0, 0);
        ctrl.CanvasService.getBlob(canvas).then(function (blob) {
            let fd = new FormData();
            fd.append("file", blob);
            fd.append(
                "upload_preset",
                process.env.CUSTOM_DOCUMENT_SCAN_PHOTO_UPLOAD_PRESET,
            );
            fd.append(
                "folder",
                "external_documents/" +
                    ctrl.growerSid +
                    "/" +
                    ctrl.growerMembershipSid,
            ),
                fd.append(
                    "context",
                    `subject=${ctrl.userSid}|grower_membership=${ctrl.growerMembershipSid}|owner=${ctrl.growerSid}|document_type=${ctrl.documentTypeSid}`,
                );
            ctrl.$http
                .post("https://api.cloudinary.com/v1_1/harvust/upload", fd, {
                    headers: { "Content-Type": undefined },
                    skipAuthorization: true,
                })
                .then(
                    function (res) {
                        ctrl.uploadStatus = UPLOAD_SUCCESS;
                        mixpanel.track("Document scanned", {
                            company_sid: ctrl.growerSid,
                            self:
                                ctrl.account.sid === ctrl.growerMembership.user,
                            document_type: ctrl.documentTypeSid,
                        });
                    },
                    function (err) {
                        ctrl.ToastService.create(
                            "customDocumentScan.FAILURE_TOAST",
                        );
                        ctrl.$state.reload();
                    },
                );
        });
    }
}

export default {
    bindings: {
        account: "<",
        userSid: "<",
        growerSid: "<",
        documentTypeSid: "<",
        growerMembershipSid: "<",
        growerMembership: "<",
    },
    controller: CustomDocumentScanController,
    template: customDocumentScanTemplate,
    name: "customDocumentScan",
    translations: { en, es },
};

export const AUTHENTICATED_PERMISSION = "authenticated";
export const ADVISOR_ONLY_PERMISSION = "advisor_only";

export const MANAGE_COMMUNICATION = "manage_announcements";
export const MANAGE_SAFETY = "manage_safety";
export const MANAGE_ONBOARDING = "manage_documents";
export const MANAGE_CREWS = "manage_crews";
export const EDIT_ADMINS = "edit_admins";
export const EDIT_FARM = "edit_farm";
export const ADD_TERMINATE_EMPLOYEES = "add_terminate_employees";
export const MANAGE_CONVERSATIONS = "manage_conversations";

// Grower permissions
export const CREATE_FARM = "create_farm";
export const EDIT_GROWER_ORGANIZATION = "edit_grower_organization";
export const EDIT_GROWER_ADMINS = "edit_grower_admins";
export const EDIT_GROWER_INTEGRATIONS = "edit_grower_integrations";
export const EDIT_GROWER_BILLING = "edit_grower_billing";
export const CAN_EXPORT_DATA_GROWER = "can_export_data";

export const PERMISSION_NAMES = [
    MANAGE_COMMUNICATION,
    MANAGE_SAFETY,
    MANAGE_ONBOARDING,
    MANAGE_CREWS,
    EDIT_ADMINS,
    EDIT_FARM,
    ADD_TERMINATE_EMPLOYEES,
    MANAGE_CONVERSATIONS,
    CREATE_FARM,
    EDIT_GROWER_ORGANIZATION,
    EDIT_GROWER_ADMINS,
    EDIT_GROWER_INTEGRATIONS,
    EDIT_GROWER_BILLING,
    CAN_EXPORT_DATA_GROWER,
];

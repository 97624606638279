import template from "./conversation.html";
import en from "./en.translation.json";
import es from "./es.translation.json";
import _ from "lodash";
import angular from "angular";
import mixpanel from "mixpanel-browser";
import { prepend } from "../../../common/common.utils";
import { advisorShareState } from "../../../../app.routes";

function reformatTwilioMessage(message) {
    return {
        body: message.body,
        author: message.author,
        index: message.index,
        dateCreated: message.dateCreated,
    };
}

class ConversationController {
    /* @ngInject */
    constructor(
        $scope,
        $document,
        $state,
        ToastService,
        $timeout,
        AdvisorMessageService,
        AdvisorSnippetService,
        $q,
        $translate,
    ) {
        this.$translate = $translate;
        this.$scope = $scope;
        this.$document = $document;
        this.$state = $state;
        this.ToastService = ToastService;
        this.newMessage = {};
        this.$timeout = $timeout;
        this.$q = $q;
        this.AdvisorMessageService = AdvisorMessageService;
        this.AdvisorSnippetService = AdvisorSnippetService;
        let ctrl = this;
        ctrl.waitingOnAdvisor = false;
        ctrl.SAVE = "save";
        ctrl.SHARE = "share";
        ctrl.shouldIndex = true;
    }

    textAreaAdjust() {
        let ctrl = this;
        let element = ctrl.$document[0].getElementById("id_raw");
        element.style.height = "1px";
        element.style.height = 25 + element.scrollHeight + "px";
    }

    $postLink() {
        let ctrl = this;
        let element = ctrl.$document[0].querySelector(".messages-container");
        angular.element(element).on("scroll", function (event) {
            let fetchOlderMessages =
                Math.abs(
                    element.scrollHeight -
                        element.clientHeight +
                        element.scrollTop,
                ) < 1;
            if (fetchOlderMessages && ctrl.messages.length > 0) {
                let earliestIndex = _.minBy(ctrl.messages, "index").index;
                if (earliestIndex) {
                    mixpanel.track("Fetching older advisor messages");
                    ctrl.twilioConversation
                        .getMessages(10, earliestIndex - 1)
                        .then((messagePaginator) => {
                            ctrl.$scope.$applyAsync(function () {
                                ctrl.messages = _.concat(
                                    ctrl.messages,
                                    _.map(
                                        _.reverse(messagePaginator.items),
                                        reformatTwilioMessage,
                                    ),
                                );
                            });
                        });
                }
            }
        });
    }

    simulateFirstMessage() {
        let ctrl = this;
        ctrl.$timeout(function () {
            ctrl.showLoadingFakeAdvisorMessage = false;
            ctrl.showSuggestedUserMessages = true;
            const input = ctrl.$document[0].getElementById("id_raw");
            input.focus();
        }, 2000);
    }

    $onInit() {
        let ctrl = this;

        // Load the first page of messages
        ctrl.twilioConversation.getMessages(10).then((messagePaginator) => {
            ctrl.$scope.$applyAsync(function () {
                ctrl.messages = _.map(
                    _.reverse(messagePaginator.items),
                    reformatTwilioMessage,
                );
                let latestMessage = _.first(ctrl.messages);
                if (latestMessage) {
                    ctrl.twilioConversation.advanceLastReadMessageIndex(
                        latestMessage.index,
                    );
                }
                if (ctrl.messages.length == 0) {
                    ctrl.newConversation = true;
                    ctrl.fakeMessageContent = ctrl.$translate.instant(
                        "advisorConversation.WELCOME_MESSAGE",
                    );
                    ctrl.$timeout(function () {
                        ctrl.showLoadingFakeAdvisorMessage = true;
                        ctrl.simulateFirstMessage();
                    }, 500);
                }

                if (ctrl.incomingMessage) {
                    ctrl.$timeout(function () {
                        ctrl.sendMessage(ctrl.incomingMessage);
                        ctrl.$state.go(".", {});
                    }, 500);
                }
            });
        });

        ctrl.twilioConversation.on("messageAdded", (message) => {
            ctrl.$scope.$applyAsync(function () {
                ctrl.messages = prepend(
                    reformatTwilioMessage(message),
                    ctrl.messages,
                );
                ctrl.twilioConversation.advanceLastReadMessageIndex(
                    message.index,
                );
                if (message.author == "harvust_advisor") {
                    mixpanel.track("Advisor message received");
                    ctrl.waitingOnAdvisor = false;
                } else {
                    ctrl.$timeout(function () {
                        ctrl.waitingOnAdvisor = true;
                    }, 2000);
                }
            });
        });
    }

    startSave(type) {
        let ctrl = this;
        ctrl.showSave = { type };
        mixpanel.track("Advisor snippet save started", {
            type,
        });
    }

    finishSave(messages, shouldIndex = false) {
        let ctrl = this;
        ctrl.showSaveSpinner = true;
        mixpanel.track("Advisor snippet save finished", {
            num_messages: messages.length,
        });
        ctrl.AdvisorSnippetService.create({
            advisor_conversation: ctrl.conversation.sid,
        }).then(function (snippet) {
            let messageResponses = _.map(messages, function (message) {
                return ctrl.AdvisorMessageService.create({
                    snippet: snippet.sid,
                    index: message.index,
                    body: message.body,
                    author: message.author,
                    message_created_date: message.dateCreated,
                });
            });
            ctrl.$q
                .all(messageResponses)
                .then(function (messages) {
                    if (ctrl.showSave.type == ctrl.SHARE) {
                        ctrl.share(snippet, shouldIndex);
                    } else {
                        ctrl.showSave = false;
                        ctrl.showSaveSpinner = false;
                        ctrl.ToastService.create(
                            "advisorConversation.SAVE_SUCCESS_TOAST",
                        );
                    }
                })
                .finally(function () {
                    ctrl.sequence = [];
                    ctrl.shouldIndex = true;
                });
        });
    }

    cancelSave() {
        let ctrl = this;
        ctrl.showSave = false;
        ctrl.sequence = [];
        mixpanel.track("Advisor snippet save cancelled");
    }

    share(incomingSnippet, shouldIndex) {
        let ctrl = this;
        incomingSnippet.share(shouldIndex).then(function (snippet) {
            let shareableUrl = ctrl.$state.href(
                advisorShareState,
                { snippet_sid: snippet.sid },
                { absolute: true },
            );
            if (navigator.share) {
                navigator
                    .share({
                        title: "Harvust Advisor",
                        text: "Check out this snippet from Harvust Advisor!",
                        url: shareableUrl,
                    })
                    .then(function () {
                        ctrl.$scope.$applyAsync(function () {
                            ctrl.showSpinner = false;
                            ctrl.showSave = false;
                            ctrl.showSaveSpinner = false;
                        });
                        mixpanel.track("Advisor snippet shared", {
                            share_method: "navigator.share",
                            shared_from: "conversation",
                        });
                    });
            } else {
                navigator.clipboard.writeText(shareableUrl).then(function () {
                    ctrl.$scope.$applyAsync(function () {
                        ctrl.ToastService.create(
                            "advisorConversation.SHARE_CLIPBOARD_TOAST",
                        );
                        ctrl.showSpinner = false;
                        ctrl.showSave = false;
                        ctrl.showSaveSpinner = false;
                    });
                    mixpanel.track("Advisor snippet shared", {
                        share_method: "clipboard",
                        shared_from: "conversation",
                    });
                });
            }
        });
    }

    sendMessage(message) {
        let ctrl = this;
        ctrl.showSpinnerMessage = true;
        if (message) {
            ctrl.twilioConversation
                .sendMessage(message)
                .then(
                    function (messageIndex) {
                        mixpanel.track("Advisor message sent");
                        ctrl.newMessage = {};
                        ctrl.twilioConversation.advanceLastReadMessageIndex(
                            messageIndex,
                        );
                    },
                    function (reason) {
                        ctrl.ToastService.create(
                            "advisorConversation.SEND_MESSAGE_FAILURE_TOAST",
                        );
                    },
                )
                .then(function () {
                    ctrl.showSpinnerMessage = false;
                    ctrl.$scope.$applyAsync();
                });
        } else {
            ctrl.showSpinnerMessage = false;
        }
    }
}

export default {
    bindings: {
        conversation: "<",
        account: "<",
        twilioConversation: "<",
        incomingMessage: "<",
    },
    controller: ConversationController,
    template: template,
    name: "advisorConversation",
    translations: { en, es },
};

import template from "./share.html";
import en from "./en.translation.json";
import es from "./es.translation.json";
import { ACTIVE, CLOSED } from "../../../communication/constants";
import _ from "lodash";
import angular from "angular";
import { ENGLISH, SPANISH } from "../../../../app.constants";
import mixpanel from "mixpanel-browser";
import { DEFAULT_PROFILE_PICTURE_ID } from "../../../common/common.constants";
import { prepend } from "../../../common/common.utils";
import logoUrl from "../../../../../assets/harvust-logo-008.svg";

class ShareController {
    /* @ngInject */
    constructor(AuthService) {
        this.LOGO_URL = logoUrl;
        this.AuthService = AuthService;
    }

    $onInit() {
        let ctrl = this;
        ctrl.messages = _.orderBy(
            ctrl.snippet.advisormessage_set,
            ["index"],
            ["asc"],
        );
        ctrl.AuthService.getAuthenticatedUserSid().then(
            function (userSid) {
                ctrl.showSignUp = false;
            },
            function () {
                ctrl.showSignUp = true;
            },
        );
    }
}

export default {
    bindings: {
        snippet: "<",
    },
    controller: ShareController,
    template: template,
    name: "advisorShare",
    translations: { en, es },
};

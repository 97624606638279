import template from "./transcription_input.html";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import _ from "lodash";
import { pEventIterator } from "p-event";
import recordingProcessor from "audio-worklet-loader!./recording-processor.worklet.js";
import {
    TranscribeStreamingClient,
    StartStreamTranscriptionCommand,
} from "@aws-sdk/client-transcribe-streaming";

const pcmEncode = (input) => {
    const buffer = new ArrayBuffer(input.length * 2);
    const view = new DataView(buffer);
    for (let i = 0; i < input.length; i++) {
        const s = Math.max(-1, Math.min(1, input[i]));
        view.setInt16(i * 2, s < 0 ? s * 0x8000 : s * 0x7fff, true);
    }
    return buffer;
};

class TranscriptionInputController {
    /* @ngInject */
    constructor($window, $translate) {
        this.$window = $window;
        this.$translate = $translate;
        this.lines = [];
        this.recordingInProgress = false;
    }

    async start() {
        let ctrl = this;

        const audioContext = new ctrl.$window.AudioContext();
        ctrl.stream = await ctrl.$window.navigator.mediaDevices.getUserMedia({
            video: false,
            audio: true,
        });

        const source1 = audioContext.createMediaStreamSource(ctrl.stream);

        try {
            await audioContext.audioWorklet.addModule(recordingProcessor);
        } catch (error) {
            console.log(`Add module error ${error}`);
        }
        ctrl.mediaRecorder = new AudioWorkletNode(
            audioContext,
            "recording-processor",
            {
                processorOptions: {
                    numberOfChannels: 1,
                    sampleRate: audioContext.sampleRate,
                    maxFrameCount: (audioContext.sampleRate * 1) / 10,
                },
            },
        );

        const destination = audioContext.createMediaStreamDestination();

        ctrl.mediaRecorder.port.postMessage({
            message: "UPDATE_RECORDING_STATE",
            setRecording: true,
        });

        source1.connect(ctrl.mediaRecorder).connect(destination);
        ctrl.mediaRecorder.port.onmessageerror = (error) => {
            console.log(`Error receving message from worklet ${error}`);
        };

        const audioDataIterator = pEventIterator(
            ctrl.mediaRecorder.port,
            "message",
        );

        const getAudioStream = async function* () {
            for await (const chunk of audioDataIterator) {
                if (chunk.data.message === "SHARE_RECORDING_BUFFER") {
                    const abuffer = pcmEncode(chunk.data.buffer[0]);
                    const audiodata = new Uint8Array(abuffer);
                    yield {
                        AudioEvent: {
                            AudioChunk: audiodata,
                        },
                    };
                }
            }
        };

        const cognitoIdentityClient = new CognitoIdentityClient({
            region: process.env.AWS_COGNITO_REGION,
        });
        const creds = fromCognitoIdentityPool({
            client: cognitoIdentityClient,
            identityPoolId: process.env.AWS_COGNITO_IDENTITY_POOL_ID,
        });
        const clientConfig = {
            region: process.env.AWS_COGNITO_REGION,
            credentials: creds,
        };
        ctrl.transcribeClient = new TranscribeStreamingClient(clientConfig);
        const command = new StartStreamTranscriptionCommand({
            IdentifyLanguage: true,
            PreferredLanguage: ctrl.$translate.use() + "-US",
            LanguageOptions: "en-US,es-US",
            MediaEncoding: "pcm",
            MediaSampleRateHertz: audioContext.sampleRate,
            AudioStream: getAudioStream(),
        });
        const data = await ctrl.transcribeClient.send(command);
        console.log("Transcribe sesssion established");

        if (data.TranscriptResultStream) {
            for await (const event of data.TranscriptResultStream) {
                if (event?.TranscriptEvent?.Transcript) {
                    for (const result of event?.TranscriptEvent?.Transcript
                        .Results || []) {
                        if (
                            result?.Alternatives &&
                            result?.Alternatives[0].Items
                        ) {
                            let completeSentence = ``;
                            for (
                                let i = 0;
                                i < result?.Alternatives[0].Items?.length;
                                i++
                            ) {
                                completeSentence += ` ${result?.Alternatives[0].Items[i].Content}`;
                            }
                            if (!result.IsPartial) {
                                ctrl.lines.push(completeSentence.trim()); // Need to find a way to add partial sentence on stop recording eventually.
                            }
                        }
                    }
                }
            }
        }
    }

    finish() {
        let ctrl = this;
        ctrl.stop();
        let finalText = _.join(ctrl.lines, " ");
        console.log("Final text:");
        console.log(finalText);
        ctrl.onUpdate({ text: finalText });
        ctrl.lines = [];
    }

    stop() {
        let ctrl = this;
        if (ctrl.mediaRecorder) {
            ctrl.mediaRecorder.port.postMessage({
                message: "UPDATE_RECORDING_STATE",
                setRecording: false,
            });
            ctrl.mediaRecorder.port.close();
            ctrl.mediaRecorder.disconnect();
        }

        if (ctrl.stream) {
            let tracks = ctrl.stream.getTracks();
            tracks.forEach((track) => track.stop());
        }

        if (ctrl.transcribeClient) {
            ctrl.transcribeClient.destroy();
        }
    }

    toggleRecording() {
        let ctrl = this;
        if (ctrl.recordingInProgress) {
            ctrl.finish();
        } else {
            ctrl.start();
        }
        ctrl.recordingInProgress = !ctrl.recordingInProgress;
    }

    $onDestroy() {
        let ctrl = this;
        ctrl.stop();
    }
}

export default {
    bindings: {
        onUpdate: "&",
    },
    name: "transcriptionInput",
    controller: TranscriptionInputController,
    template: template,
};

import template from "./saved.html";
import en from "./en.translation.json";
import es from "./es.translation.json";
import _ from "lodash";

class SavedController {
    /* @ngInject */
    constructor() {}

    $onInit() {
        let ctrl = this;
        ctrl.messages = _.groupBy(ctrl.messages, "snippet");
        ctrl.snippets = _.orderBy(ctrl.snippets, ["created"], ["desc"]);
    }
}

export default {
    bindings: {
        snippets: "<",
        account: "<",
        messages: "<",
    },
    controller: SavedController,
    template: template,
    name: "advisorSaved",
    translations: { en, es },
};

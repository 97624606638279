import advisorConversation from "./advisorConversation";
import advisorHome from "./advisorHome";
import advisorSaved from "./advisorSaved";
import advisorBase from "./advisorBase";
import advisorShare from "./advisorShare";
import advisorSnippet from "./advisorSnippet";
import advisorSignUp from "./advisorSignUp";

export default [
    advisorConversation,
    advisorHome,
    advisorSaved,
    advisorBase,
    advisorShare,
    advisorSnippet,
    advisorSignUp,
];

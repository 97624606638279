import template from "./document_scan_start.html";
import en from "./en.translation.json";
import es from "./es.translation.json";
import mixpanel from "mixpanel-browser";
import {
    UPLOAD_PENDING,
    UPLOAD_SUCCESS,
    FACING_MODE_ENVIRONMENT,
} from "../../../common/common.constants";

class DocumentScanStartController {
    /* @ngInject */
    constructor(
        $document,
        ToastService,
        $http,
        $state,
        $window,
        $q,
        CanvasService,
    ) {
        this.$document = $document;
        this.ToastService = ToastService;
        this.$http = $http;
        this.$state = $state;
        this.$window = $window;
        this.$q = $q;
        this.CanvasService = CanvasService;

        this.UPLOAD_PENDING = UPLOAD_PENDING;
        this.UPLOAD_SUCCESS = UPLOAD_SUCCESS;
        this.cameraAllowed = true;

        this.IDOptions = {};
    }

    openFilePicker(documentTypeSid) {
        let ctrl = this;
        ctrl.$document[0].getElementById("idImage").click();
        ctrl.IDOptions.documentTypeSid = documentTypeSid;
    }

    captureVideo() {
        let ctrl = this;
        let options = {
            video: {
                width: { ideal: 1920 },
                height: { ideal: 1080 },
                facingMode: FACING_MODE_ENVIRONMENT,
                frameRate: {
                    ideal: 30,
                },
            },
            audio: false,
        };
        ctrl.$q
            .when(ctrl.$window.navigator.mediaDevices.getUserMedia(options))
            .then(
                function (stream) {
                    ctrl.cameraAllowed = true;
                    ctrl.video.srcObject = stream;
                    ctrl.video.play();
                },
                function () {
                    ctrl.cameraAllowed = false;
                },
            );
    }

    openBackupPhoto(documentTypeSid) {
        let ctrl = this;
        ctrl.usingBackupPhoto = true;
        ctrl.video = ctrl.$document[0].getElementById("video");
        ctrl.captureVideo();
        ctrl.IDOptions.documentTypeSid = documentTypeSid;
    }

    supportsCapture() {
        let ctrl = this;
        var input = ctrl.$document[0].createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("capture", "user");
        return "capture" in input && input.capture === "user";
    }

    $onInit() {
        let ctrl = this;
        ctrl.clientSupportsCapture = ctrl.supportsCapture();
    }

    endVideoCapture() {
        let ctrl = this;
        for (let track of ctrl.video.srcObject.getTracks()) {
            track.stop();
        }
    }

    $onDestroy() {
        let ctrl = this;
        if (ctrl.video) {
            ctrl.endVideoCapture();
        }
    }

    handleCameraPhoto(options) {
        let ctrl = this;
        ctrl.uploadStatus = UPLOAD_PENDING;

        const reader = new FileReader();
        reader.onload = function (e) {
            const img = new Image();
            img.onload = function () {
                let targetWidth = 2000; // Target width in pixels
                let targetHeight;
                if (img.width > targetWidth) {
                    // Calculate the target height to maintain the aspect ratio
                    const aspectRatio = img.height / img.width;
                    targetHeight = targetWidth * aspectRatio;
                } else {
                    // Set canvas size to image size
                    targetWidth = img.width;
                    targetHeight = img.height;
                }

                let canvas = ctrl.$document[0].getElementById("canvas");
                let context = canvas.getContext("2d", { alpha: false });
                // Set canvas size to target dimensions
                canvas.width = targetWidth;
                canvas.height = targetHeight;

                // Draw the image onto the canvas
                context.drawImage(img, 0, 0, canvas.width, canvas.height);

                ctrl.CanvasService.getBlob(canvas).then(function (blob) {
                    ctrl.upload(blob, options.documentTypeSid);
                });
            };
            img.src = e.target.result;
        };
        reader.readAsDataURL(options.file);
    }

    reset() {
        let ctrl = this;
        ctrl.$state.reload();
    }

    takePhoto() {
        let ctrl = this;
        let canvas = ctrl.$document[0].getElementById("canvas");
        let video = ctrl.video;

        ctrl.uploadStatus = UPLOAD_PENDING;

        // Set image
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        let context = canvas.getContext("2d", { alpha: false });
        video.pause();

        const videoWidth = video.videoWidth;
        const videoHeight = video.videoHeight;
        const offsetHeight = video.offsetHeight;
        const offsetWidth = video.offsetWidth;

        // Calculate aspect ratios
        const videoAspectRatio = videoWidth / videoHeight;
        const displayedAspectRatio = offsetWidth / offsetHeight;

        let cropWidth, cropHeight, cropX, cropY, finalWidth, finalHeight;

        // Determine the cropping dimensions and position
        if (videoAspectRatio > displayedAspectRatio) {
            // Video is wider than the displayed area
            cropHeight = videoHeight;
            cropWidth = cropHeight * displayedAspectRatio;
            cropX = (videoWidth - cropWidth) / 2;
            cropY = 0;
        } else {
            // Video is taller than the displayed area
            cropWidth = videoWidth;
            cropHeight = cropWidth / displayedAspectRatio;
            cropX = 0;
            cropY = (videoHeight - cropHeight) / 2;
        }
        canvas.width = cropWidth;
        canvas.height = cropHeight;

        // Calculate the horizontal cropping offset (since the video is centered)
        context.drawImage(
            video,
            cropX,
            cropY,
            cropWidth,
            cropHeight,
            0,
            0,
            cropWidth,
            cropHeight,
        );

        ctrl.CanvasService.getBlob(canvas).then(function (blob) {
            ctrl.upload(blob, ctrl.IDOptions.documentTypeSid);
        });
    }

    upload(file, documentTypeSid) {
        let ctrl = this;
        let fd = new FormData();
        fd.append("file", file);
        fd.append(
            "upload_preset",
            process.env.CUSTOM_DOCUMENT_SCAN_PHOTO_UPLOAD_PRESET,
        );
        fd.append(
            "folder",
            "external_documents/" +
                ctrl.growerSid +
                "/" +
                ctrl.growerMembershipSid,
        ),
            fd.append(
                "context",
                `subject=${ctrl.userSid}|grower_membership=${ctrl.growerMembershipSid}|owner=${ctrl.growerSid}|document_type=${documentTypeSid}`,
            );
        ctrl.$http
            .post("https://api.cloudinary.com/v1_1/harvust/upload", fd, {
                headers: { "Content-Type": undefined },
                skipAuthorization: true,
            })
            .then(
                function (res) {
                    ctrl.uploadStatus = UPLOAD_SUCCESS;
                    mixpanel.track("Document scanned", {
                        company_sid: ctrl.growerSid,
                        self: ctrl.account.sid === ctrl.growerMembership.user,
                        document_type: ctrl.documentTypeSid,
                    });
                },
                function (err) {
                    ctrl.ToastService.create(
                        "customDocumentScan.FAILURE_TOAST",
                    );
                    ctrl.$state.reload();
                },
            );
    }
}

export default {
    controller: DocumentScanStartController,
    bindings: {
        account: "<",
        userSid: "<",
        growerSid: "<",
        documentTypeSid: "<",
        growerMembershipSid: "<",
        documentTypes: "<",
        growerMembership: "<",
    },
    template: template,
    name: "documentScanStart",
    translations: { en, es },
};

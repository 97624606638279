import signStartPaperwork from "./signStartPaperwork";
import customDocumentScan from "./customDocumentScan";
import documentScanStart from "./documentScanStart";
import myDocuments from "./myDocuments";

export default [
    signStartPaperwork,
    customDocumentScan,
    documentScanStart,
    myDocuments,
];
